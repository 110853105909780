import React from "react"
import Layout from "../components/layout"
import styled from '@emotion/styled';
import ServiciosPreview from '../components/servicioPreview';
import useServicios from '../hooks/use-servicios';
import ContenidoIndex from '../components/contenidoIndex';
import SegundoContenidoIndex from '../components/segundoContenidoindex';
import DescripcionComputadora from '../components/desCompu';
import DescripcionWeb from '../components/desWeb';
import Frontal from '../components/frontal';



const ListadoServicios = styled.ul`
    max-width:1200px;
    width: 95%; 
    margin: 4rem auto 0 auto;
    @media (min-width: 768px){
        display:grid;
        grid-template-columns: repeat(3, 2fr);
        grid-auto-rows: 1fr;
        column-gap: 3rem;
    }
`

/* 2.24.91 */


const IndexPage = () => {
  const servicios = useServicios();

return(
  
  <Layout>
      

    <Frontal/>
    
    <ListadoServicios id="servicios">
          {servicios.map(servicio => (
            <ServiciosPreview key={servicio.id} servicio={servicio} />
          ))}
    </ListadoServicios>
    <ContenidoIndex/>
    <DescripcionComputadora/>
    
  </Layout>
 
)
}
export default IndexPage
