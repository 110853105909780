import React from 'react';
import { Parallax,Background } from 'react-parallax';
import { css } from '@emotion/core';
import {graphql,useStaticQuery} from 'gatsby'

const insideStyles = {
  padding: 20,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",

};
const ContenidoIndex = () => {


  
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "banner_computadoras.jpg" }) {
        sharp: childImageSharp {
          fluid( quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  console.log(image);
 return(
    <div css={css`padding-top: 0px;`}>
  <Parallax
      bgImage={image.sharp.fluid.src}
      strength={400}
      renderLayer={percentage => (
        <div>
          <div
            style={{
              position: "absolute",
              background: `rgba(83, 83, 100, ${percentage * 1})`,
              left: "50%",
              top: "50%",
              borderRadius: "50%",
              transform: "translate(-50%,-50%)",
              width: percentage * 700,
              height: percentage * 700
            }}
          />
        </div>
      )}
    >
      <div style={{ height: 500 }}>
        <div style={insideStyles}>

        <h3 css={css`
          font-size:  3rem;
          text-align: center;
          color: white;
          `}>¿Necesitas una computadora </h3>
        <h3 css={css`
        font-size: 3rem;
        text-align: center;
        color: white;
        `}>para ti, tu empresa o tu escuela?  </h3>
        
        
       
        </div>
      </div>
      
    </Parallax>
  
       
    </div>
    
 )
}
 
export default ContenidoIndex;