import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import Fade from 'react-reveal/Fade';
import Logos from '../images/logos.svg';
import Background from '../images/fondovector.svg';
const ListadoPrecios = styled.ul`
  
max-width:1200px;
width: 100%;
margin: 4rem auto 0 auto;
@media (min-width: 768px){
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
}
`

const DescripcionComputadora = () => {


    return (  
       
        <ListadoPrecios>
             <Fade right>
         <div css={css`    
      background-image:  url(${Background});
      color:white; 
      padding:2rem;
      border: 1px solid;
      box-shadow: 0 6px 10px 0 rgba(0,0,0,0.2);
   `}>
           <h3 css={css`font-size:4rem; text-align:center;`}>
        ¿Porque somos tu mejor opcion?
            </h3>
       <h3 css={css`font-size:1.5rem; text-align: justify;`}>
        Armamos equipos 100% actualizables dandole un muy largo tiempo de vida util para cumplir tus retos académicos o profesionales.
        </h3>
        <h3 css={css`font-size:1.5rem; text-align: justify;`}>
        Trabajamos con los mejores marcas de componentes, al mejor precio y con una garantia de satisfaccion y seguimiento al cliente.
        </h3>
        <h3 css={css`font-size:1.6rem; text-align: justify;`}>
        Somos los unicos que te damos el mantenimiento preventivo de tu equipo el primer año sin costo.
        </h3>
       </div>
       </Fade> 


       <Fade left>
        <img css={css`width: 100%; height:auto;`} src={Logos}/>  <br/><br></br></Fade>
        </ListadoPrecios>
       
       
    );
}
 
export default DescripcionComputadora;