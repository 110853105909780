import React from 'react';
import { css } from "@emotion/core"
import Navegacion from "./nav";
import styled from '@emotion/styled';
import {Link} from 'gatsby';
import Monarca from '../images/MonarcaTekLogo.png'
import whatsapp from '../images/whatsapp1.svg';
import fb from '../images/facebook.svg';
const EnlaceHome = styled(Link)`
  color: #FFF;
  text-align: center;
  text-decoration: none;

`;

const Header = () => {
    return (
      <header
        css={css`
        
          padding: 1rem;
        `}
      >
        <div css={css`
        background-color: #464672 ;
          padding: 1rem;
          color: white;
        `}
      >
        <h2 css={css`text-align:center;`} >Tu pagina web + aplicacion Android/iOS desde $6800.00 , Llamanos  5548701797 <a href="https://api.whatsapp.com/send?phone=525548701797&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Monarcatek%20"> <img width="25px" src={whatsapp}></img> </a> <a href="https://www.facebook.com/monarcatek"><img width="25px" src={fb}></img> </a></h2>
      </div>
        <div
          css={css`
            max-width: 1200px;
            margin: 0 auto;
            @media (min-width: 768px) {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          `}
        >
          <EnlaceHome to="/">
       <img css={css`width:25rem;`} src={Monarca}/> 
          </EnlaceHome>

          <Navegacion />
        </div>
      </header>
    )
}
 
export default Header;