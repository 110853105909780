import React from 'react';
import { Parallax,Background } from 'react-parallax';
import { css } from '@emotion/core';
import {graphql,useStaticQuery} from 'gatsby'
import styled from '@emotion/styled';
import degradado from '../images/FronPage1.svg';
import render from '../images/render.svg';
import iphone from '../images/ayfon.svg';
import Header from "./header"
import {Link} from 'gatsby';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const Grid = styled.ul`

width: 100%;
margin: 0 auto 0 auto;
@media (min-width: 768px){
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    
}
`
const Texto = styled.div`

      padding-top: 1rem;
      padding-left: 2rem;
      color: white;
      font-size:4rem;
@media (min-width: 768px){
      padding-top: 4rem;
      padding-left: 5rem;
      color: white;
      font-size:4rem;
    
}
`

const Texto2 = styled.div`

     text-align:center;
     font-weight:700;
     font-size:3rem;
@media (min-width: 768px){
  text-align:center;
      font-weight:700;
      font-size:3rem;
    
}
`


const Imagen = styled.img`
width:150%; height:auto;

@media (min-width: 768px){
  width:100%; height:auto;
}
`
const Imagen2 = styled.img`
width:75%; height:auto;

@media (min-width: 768px){
  width:50%; height:auto;
}
`

const Boton = styled(Link) ` 
  margin-top: 2rem;
  padding: 2rem;
  background-color: #18184f;
  width: 70%;
  color: #FFF;
  display: block;
  text-decoration: none;
  text-transform:uppercase;
  font-weight:700;
  text-align:center;
  border-radius: 25px;
  font-size:1rem;
      @media (min-width: 768px){
      margin-top: 2rem;
      padding: 2rem;
      background-color: #18184f;
      width: 70%;
      color: #FFF;
      display: block;
      text-decoration: none;
      text-transform:uppercase;
      font-weight:700;
      text-align:center;
      border-radius: 25px;
      font-size:2rem;
    
}
`;

const Containerv2 = styled.div ` 
font-size: 1.8rem;  padding: 3rem;
    @media (min-width: 768px){
      font-size: 1.8rem;  padding: 10rem;
  
}
`
const Frontal = () => {


  
/*   const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "FronPage.png" }) {
        sharp: childImageSharp {
          fluid( quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `) */
 
 return(
  <>
  <div css={css`    
      background-image:  url(${degradado});
      width: 100%;
      height: auto;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @media (min-width: 768px){
   
    
}
   `}>
    
        
    <div css={css`      
      height:110rem;
    
   `}>
         <Header />
     <Grid>
     
   <Texto>
   <Fade right>
      <h3>Desarrollo de aplicaciones Web Progresivas</h3>
      <h6>Lo mejor de dos mundos, paginas web y aplicaciones moviles.</h6>
      </Fade>
      <Zoom>
      <Boton to={'desarrolloweb'} >Cotiza tu aplicacion web</Boton>
      </Zoom>
    </Texto>
    
    <div css={css`      
      padding: 10rem;
      color: white;
      font-size:4rem;
   `}>
     <Fade top>  <div css={css`align-items:center; text-align:center;`}><Imagen src={render}/></div> </Fade>
     
    </div>
    </Grid>

       </div>

 </div>
    <div css={css`height:10rem;`}></div>
<div>
      <Texto2>¿Que es una aplicacion web progresiva?</Texto2>
      <Grid>
          <Containerv2>
            <h3 css={css`font-size: 2rem; font-weight:700;`}>¡Aplicaciones Accesibles para todas las empresas!</h3>
            <p>Las <strong>PWA</strong> (Aplicaciones web progresivas) son aplicaciones intermedias entre web y móviles y simulan la experiencia de una aplicacion nativa</p>
            <ol>
              <li>Tienen acceso a la camara,gps,bluetooth y sistemas de archivos</li>
              <li>Instalables desde navegador para dispositivos iOS y Android</li>
              <li>Actualizables automaticante gracias a que cuentan con certificado Https</li>
              <li>Reciben notificaciones push para que tus clientes siempre esten al tanto de tus novedades</li>
              <li>Ligeras, rapidaz y se posicionan excelente gracias a que cumplen con las reglas SEO de Google</li>
            </ol>
          </Containerv2>
          <div  css={css`align-items:center; text-align:center;`}>
          <Fade top> <Imagen2 src={iphone}/></Fade>
          </div>
      </Grid>
    </div>
 </>    
   
    
 )
}
 
export default Frontal;