import {graphql,useStaticQuery} from 'gatsby';

const useServicios = () => {
    const data = useStaticQuery(
        graphql  `query{
            allDatoCmsServicio{
                nodes{
                titulo
                id
                slug
                descripcioncorta
                descripcionlarga
                portada{
                    fluid(maxHeight: 400){
                   ...GatsbyDatoCmsFluid
                            }
                        }
                    }
                }
            } `
    )
    return data.allDatoCmsServicio.nodes.map(servicio => ({
      titulo: servicio.titulo,
      id: servicio.id,
      descripcioncorta: servicio.descripcioncorta,
      descripcionlarga: servicio.descripcionlarga,
      portada: servicio.portada,
      slug: servicio.slug,
    }))
   
}


 
export default useServicios;