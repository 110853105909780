import React from 'react';
import Image from 'gatsby-image';
import {css} from '@emotion/core';
import styled from '@emotion/styled';
import {Link} from 'gatsby';
import Fade from 'react-reveal/Fade';

const Boton = styled(Link) ` 
    margin-top: 2rem;
    padding: 1rem;
    background-color: #18184f;
    width: 100%;
    color: #FFF;
    display: block;
    text-decoration: none;
    text-transform:uppercase;
    font-weight:700;
    text-align:center;

`;



const ServicioPreview = ({servicio}) => {
  
  
    const {descripcionlarga,portada,titulo,slug} = servicio;
    return (

      


      <div
        css={css`
         
          margin-bottom: 2rem;
        `}
      >
       <div css={css`position: relative;`} >
       <a href={slug} >
      <Fade>
      <Image  fluid={portada.fluid}  fadeIn="soft"/>
      </Fade>
       </a>
      
       </div>
        <div
          css={css`
            padding: 3rem;
          `}
        >
         
          <Fade top>
          <h3 css={css`
          font-size:  3rem;
          `}>{titulo} </h3>
        </Fade>
          <p css={css`font-size: 2rem;`}>{descripcionlarga}</p>
          
         <Fade> <Boton to={slug}>Ver Servicio</Boton></Fade>
        </div>
      </div>
    )
}
 
export default ServicioPreview;