import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import {graphql,useStaticQuery} from 'gatsby'
import Image from 'gatsby-image';
import Fade from 'react-reveal/Fade';
const ListadoPrecios = styled.ul`
  
max-width:1200px;
width: 95%;
margin: 4rem auto 0 auto;
@media (min-width: 768px){
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
}
`

const DescripcionWeb = () => {

    const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "code.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
 
    return (  
     
        <ListadoPrecios>
           <Fade left>
       <div  css={css`    
     background-color: rgba(224, 83, 83, 0.65); 
    color:white; 
    padding:2rem;
   `}>
           <h2 css={css`font-size:3.2rem; text-align:center;`}>
        ¿Porque somos diferentes al resto?
            </h2>
       <h3 css={css`font-size:2rem; text-align: justify;`}>
         Desarrollamos paginas web completas, con alta seguridad y rapidez
        </h3>
        <h3 css={css`font-size:2rem; text-align: justify;`}>
        Nuestros tiempos de carga son hasta 50% más rapidos que otras plataformas y otras empresas de desarrollo de software
        </h3>
        <h3 css={css`font-size:2rem; text-align: justify; color: rgba(34, 49, 63)`}>
        Inluimos en todos nuestros paquetes el desarrollo de tu propia progressive web app. Una aplicacion facilmenten instalable en todos los dispositivos de tus clientes
        </h3>
        <h3 css={css`font-size:2rem; text-align: justify; `}>
        Utilizamos herramientas modernas, tu web siempre estara actulizada y administrada.
        </h3>
       </div>
       </Fade>

        <Fade right>
          <Image  fluid={image.sharp.fluid} />
        </Fade>
        </ListadoPrecios>
      
    );
}
 
export default DescripcionWeb;